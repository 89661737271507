<template>
    <div class="body">
        <p class="rule-title">分销员入住协议</p>
        <div class="line1"></div>
        <div class="line2"></div>
        <div class="rule-wrapper">
            <div class="subhead">第一条：立约目的</div>
            <div class="content">为了规范一碗福州平台经营秩序，明确供货商、分销员（即一碗生活家，下同）及一碗福州平台的权利义务，保障各方及消费者的合法权益，特拟定本协议。</div>
            <div class="subhead">第二条：签约主体</div>
            <div class="content">本协议由福州视通传媒有限公司（以下统称“一碗福州”）与申请成为分销员签署。</div>
            <div class="subhead">第三条：协议生效和适用范围</div>
            <div class="content">
                <p>3.1、分销员通过网络页面点击确认或以其他方式选择接受本协议，即表示与“一碗福州”平台已达成协议并同意接受本协议的全部约定内容。本协议自申请获得平台审核通过之时起生效。</p>
                <p>3.2、分销员的申请在获得“一碗福州”平台审核通过后，在本协议有效期间，分销员于一碗福州平台开展经营活动应遵守本协议的约定。</p>
            </div>
            <div class="subhead">第四条：入驻一碗福州平台分销员的条件和平台审核流程</div>
            <div class="content">
                <p>4.1、申请入驻一碗福州平台需先登录一碗商城，并通过申请页面提交申请。一碗福州平台将基于对申请人提交的申请资料的审核对申请进行评定。</p>
                <p>4.2、如果申请人的申请通过一碗福州平台审核的，申请人可在审核通过之日起按照规定，在线申请并使用供货商提供的分销商品进行分销，双方正式确立分销合作关系。分销合作关系确立后，分销员可在一碗福州授权范围内下载产品信息。</p>
            </div>
            <div class="subhead">第五条：一碗福州平台分销员的权利和义务</div>
            <div class="content">
                <p>5.1、分销员在一碗福州平台开展的经营活动中应遵循诚实守信原则，遵纪守法、本着保障商品质量、提高服务品质，为消费者提供优质的商品和服务，快速、高效的处理交易纠纷。</p>
                <p>5.2、一碗福州平台所有分销商品的分销分润都将公示在一碗福州【分销中心-分销商品】列表中。分销员在售出产品核销完成后可以获得一碗福州设定的商品分销分润。</p>
                <p>5.3、在向消费者销售及向供货商采购的过程中，分销员需遵守以下规定：</p>
                <p>5.3.1、分销员必须履行对消费者的承诺。如果分销员与一碗福州之间的约定不清或不能覆盖分销员对消费者的销售承诺，风险由分销员自行承担；</p>
                <p>5.3.2、分销员承诺：分销商品所产生的销售订单均由一碗福州供货，以保证产品品质；分销员擅自供货的，造成消费者人身、财产损害的以及造成一碗福州平台经济损失、声誉受损等，将承担相应的责任。</p>
                <p>5.3.3、分销员有义务确认买家收货地址的有效性，有义务及时支付采购单货款给一碗福州，有义务在消费者收到货物后，及时针对采购单确认收货。</p>
                <p>5.4、分销员需遵守与供货商的约定进行销售行为。</p>
                <p>5.5、分销员与一碗福州有权自行协商解除双方的分销合作关系。如分销员违反一碗福州平台规则受到一碗福州平台处罚，则一碗福州有权立即解除与分销员的分销合作关系。但分销合作关系解除前双方已确定的权利、业务关系不受分销合作关系终止的影响。</p>
                <p>5.6、一碗福州承诺其提供产品均为真品，在分销员已签署本协议的情况下,如消费者向分销员发起维权且一碗福州平台判定消费者赔付申请成立，则分两种情况处理：</p>
                <p>5.6.1、分销员按照本协议的约定向消费者承担其应承担的责任，如属一碗福州原因导致，则分销员在承担以上责任后可向供货商追偿。</p>
                <p>5.6.2、分销员未依照约定履行相关义务的，一碗福州平台有权对分销员进行相应处罚。</p>
            </div>
            <div class="subhead">第六条：退出服务和终止</div>
            <div class="content">
                <p>6.1、一旦分销员不再符合申请条件或违反本协议规定内容的，则一碗福州平台有权将分销员清退出一碗福州平台。</p>
                <p>6.2、本协议在符合以下任意一项情形时终止：</p>
                <p>6.2.1、分销员按本协议的规定退出或被清退出一碗福州平台；</p>
                <p>6.2.2、如分销员在线签署的本协议因任何原因终止，则本协议将同时终止；</p>
                <p>6.2.3、本协议调整时，分销员明示并通知一碗福州平台不愿接受新的协议内容，则本协议自一碗福州平台收到分销员通知之日起终止；</p>
                <p>6.2.4、一碗福州平台有权根据分销员经营情况单方通知分销员终止本协议；</p>
                <p>6.2.5、其他协议终止条件发生或实现，导致本协议终止。</p>
                <p>6.3、本协议终止后分销员无权继续从一碗福州处分销产品、进行分销活动，但本协议终止前已经发生的交易，分销员仍需依照本协议的约定履行义务。本协议终止后，分销员签署的《消费者保障服务协议》仍在有效期的，分销员仍应依照该等消保协议的约定向消费者提供消费者保障服务。</p>
            </div>
            <div class="subhead">第七条：一碗福州平台的权利和义务</div>
            <div class="content">
                <p>7.1、一碗福州平台根据本协议的约定向分销员提供信息及数据管理（包括但不限于信息发布、订单及采购单）服务。</p>
                <p>7.2、因网上平台的特殊性，一碗福州平台没有义务对所有分销员的信息、所有的交易行为以及有关的其他事项进行事先审查，但如存在下列情况：</p>
                <p>7.3.1、用户或其他第三方通知一碗福州平台，认为某个具体分销员或具体交易事项可能存在重大问题；</p>
                <p>7.3.2、用户或其他第三方向一碗福州平台告知供货商平台上有违法或不当行为的； 一碗福州平台以普通非专业交易者的知识水平标准对相关内容进行判别，可以明显认为这些内容或行为具有违法或不当性质的，一碗福州平台有权根据不同情况选择保留或删除相关信息或继续、停止对该用户提供服务。</p>
                <p>7.4、一碗福州平台有权对分销员的基本信息、产品信息及采购交易信息进行查阅，发现存在任何问题或怀疑，均有权向分销员发出询问及要求改正的通知或者直接作出删除等处理。</p>
                <p>7.5、经国家生效法律文书或行政处罚决定确认分销员存在违法行为，或者一碗福州平台有足够事实依据可以认定分销员存在违法或违反协议行为的，一碗福州平台有权公布分销员的违法和/或违规行为。</p>
            </div>
            <div class="subhead">第八条：其他约定</div>
            <div class="content">
                <p>8.1、本协议适用中华人民共和国大陆地区法律。因一碗福州平台与分销员就本协议的签订、履行或解释发生争议，双方应努力友好协商解决。如协商不成，一碗福州平台和分销员同意由一碗福州平台住所地法院管辖审理双方的纠纷或争议。</p>
                <p>8.2、本协议内容包括协议正文、附件及所有一碗福州平台已经发布的或将来可能发布的一碗福州平台规则、经营规范等文件。这些文件是本协议不可分割的一部分，与协议具有相同法律效力。</p>
                <p>8.3、一碗福州平台有权以修改、更新等方式不时调整本协议和相关规则的内容，相关内容将公布于一碗福州平台的官方网站上或以其他方式通知分销员。除非你明示并通知一碗福州平台不愿接受调整后的内容，否则调整后的内容将于公布之日或通知另行指定的日期开始生效。</p>
                <p>9.担保交易服务协议</p>
                <p>鉴于福州视通传媒有限公司（以下简称“一碗福州”）为合作商家进行在线交易的用户提供货款代收代付服务，用户在使用一碗福州平台进行交易之前，均应仔细阅读本声明，用户通过网络页面点击确认或以其他方式选择接受本声明，即表示用户同意接受本声明的全部内容。</p>
                <p>如果你对本声明的条款有疑问的，请通过小碗客服渠道进行询问，一碗福州将向你解释条款内容。如果你不同意本声明的任意内容，或者无法准确理解一碗福州对条款的解释，请谨慎进行后续操作。</p>
                <p>本声明适用中华人民共和国大陆地区法律。因一碗福州与用户就本声明的确认、履行或解释发生争议，双方应努力友好协商解决。如协商不成，一碗福州和用户同意由一碗福州住所地法院管辖审理双方的纠纷或争议。</p>
                <p>本声明内容包括声明正文及所有一碗福州已经发布的或将来可能发布的一碗福州服务的使用规则。所有规则为本声明不可分割的一部分，与声明正文具有相同法律效力。本协议未尽事宜，用户需遵守一碗福州网站上公布的《一碗福州服务协议》及相关规则。 </p>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'Rule',
    mounted () {
    }
  }
</script>

<style scoped>
    .body{
        background: white
    }
    .rule-title{
        width: 100vw;
        text-align: center;
        font-size: 7vw;
        font-weight: 600;
        padding: 4vw 0 2vw 0;
    }
    .subhead{
        font-size: 4.2vw;
        font-weight: 600;
        color: #333;
        margin: 2vw 0 1vw 0;
    }
    .content{
        font-size: 3.6vw;
        color: #444
    }
    .line1{
        width: 92vw;
        height: 3px;
        background-color: #333;
        margin: 0.5vw 4vw 0 4vw;
    }
    .line2{
        width: 92vw;
        height: 2px;
        background-color: #333;
        margin: 0.5vw 4vw 0 4vw;
    }
    .rule-wrapper {
        padding: 0.5vw 6vw 5vw 6vw;
    }
</style>
